import React, { useMemo } from "react";
import { useStore } from "store";
import { className } from "utils/className";
import { isMobile } from "utils/devices";
import {
  hireUSContainer,
  linksWrapper,
  hireUS,
  hireUSButton,
  logoFill,
  dark,
  mobile,
} from "./HireUS.module.scss";

interface HireUSProps {
  forceDark?: boolean;
}

export function HireUS(props: HireUSProps) {
  const { mapStyle } = useStore();
  const isMobileDevice = useMemo(() => isMobile(), []);

  return (
    <div
      className={className(
        hireUSContainer,
        mapStyle.includes("dark") || props.forceDark ? dark : "",
        isMobileDevice ? mobile : ""
      )}
    >
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 143.5 143.5">
          <g className={logoFill}>
            <path d="M143.5 143.5H0V0h143.5v35.8h-7.2V7.3H7.3v129h129v-29.8h7.2z" />
            <path d="M62.1 85.4H80v6H54.7V53.1h7.4v32.3zM110.4 97.1h-24v-5.7h24v5.7z" />
          </g>
          <circle fill="#30AAB3" cx="26.4" cy="27.3" r="8.3" />
        </svg>
        <div className={linksWrapper} data-v-51e98a8e="">
          <a
            target="_blank"
            className={hireUS}
            rel="noopener"
            href="https://www.leniolabs.com/services/"
          >
            Crafted with <span>♥</span> by Leniolabs_
          </a>
          <a
            target="_blank"
            className={hireUSButton}
            rel="noopener"
            href="https://www.leniolabs.com/services/"
          >
            Hire our front end team!
          </a>
        </div>
      </div>
    </div>
  );
}
