import * as React from "react";
import devices from "images/devices.svg";
import { Button } from "components/common/Button";
import {
  container,
  title,
  description,
  image,
  withBorderLeft,
  unableToDisplay,
} from "./Mobile.module.scss";

export const Mobile = () => (
  <div className={container}>
    <h1 className={title}>
      The map you want, <br />
      the way you need it
    </h1>
    <p className={[description, withBorderLeft].join(" ")}>
      A simple API that provides geographic data for any location in the world,
      with support for GeoJSON, TopoJSON and SVG. User REST GIS to search for a
      place and instantly get its geometry collection.
    </p>
    <Button to="/documentation">Read the docs</Button>
    <img className={image} src={devices} alt="devices" />
    <p className={[description, unableToDisplay].join(" ")}>
      Sorry, we're unable to display this page on a mobile device or tablet.
      Please log in from a computer to use this tool.
    </p>
  </div>
);
